import useProjects from 'hooks/useProjects';
import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from 'shared/components/header';
import LoadingScreen from 'shared/components/loading-screen';
import Projects from 'shared/components/projects';
import ProjectsSelector from 'shared/components/projects/selector';
import { TabProvider } from 'shared/contexts/tabs-context';

const Loadable = (Component: any) => (props: any) => (
  <Suspense
    fallback={(
      <LoadingScreen sx={{
        top: 0, left: 0, width: 1, zIndex: 9999, position: 'fixed',
      }}
      />
    )}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(React.lazy(() => import('pages/home-page')));
const ResultPage = Loadable(React.lazy(() => import('pages/result-page')));
const PdfPage = Loadable(React.lazy(() => import('pages/pdf-page')));

export default function Router() {
  const [loadProjects, setLoadProjects] = useState(false);
  const { loadedProjects } = useProjects(loadProjects);
  const enableProjects = process.env.REACT_APP_ENABLE_PROJECTS;

  useEffect(() => {
    if (enableProjects !== 'false') setLoadProjects(true);
  }, []);

  return (
    <div className="main-frame">
      <Suspense fallback="Loading...">
        <TabProvider>
          <Header />
          <div className="container">
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/results" component={ResultPage} />
              <Route path="/pdfView" component={PdfPage} />
            </Switch>
          </div>
          <Projects />
          <ProjectsSelector />
        </TabProvider>
      </Suspense>
    </div>
  );
}
