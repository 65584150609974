import React, { createContext, useState } from 'react';

export enum SearchModeType {
  Query = 'query',
  Chat = 'chat',
  ChatV2 = 'chatv2 (alpha)',
  AskMode = 'q&a'
}

interface SearchModeContextProps {
  mode: SearchModeType;
  setMode: (mode: SearchModeType) => void;
}

const defaultMode: SearchModeType = process.env.REACT_APP_DEFAULT_MODE as SearchModeType || SearchModeType.Query;

export const SearchModeContext = createContext<SearchModeContextProps>({
  mode: defaultMode,
  setMode: () => {},
});

interface SearchModeProviderProps {
  children: React.ReactNode;
}

export const SearchModeProvider = ({ children }: SearchModeProviderProps) => {
  const [mode, setMode] = useState<SearchModeType>(defaultMode);

  return (
    <SearchModeContext.Provider value={{ mode, setMode }}>
      {children}
    </SearchModeContext.Provider>
  );
};
