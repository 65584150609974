type Field = {
  name: string,
  placeholder: string,
  searchField: string,
  value: string,
  type: string,
  suggestions?: string[],
  suggesterName?: string,
  suggesterField?: string,
};

export const fieldsConfigurationDefault: Field[] = [
  {
    name: 'content',
    placeholder: 'Keyword Search',
    searchField: 'content',
    value: '',
    type: 'text',
  },
];

const inputFieldKeys = Object.keys(process.env)
  .filter((key) => key.startsWith('REACT_APP_QUERY_INPUT_'));

export const fieldsConfiguration: Field[] = inputFieldKeys.length > 0
  ? inputFieldKeys.map((key) => {
    const fieldJson = process.env[key] || '{}';
    return JSON.parse(fieldJson) as Field;
  })
  : fieldsConfigurationDefault;

export const indexFieldsNames = {
  title: 'metadata_storage_name',
  content: 'content',
  score: 'score',
  path: 'blob_storage_path',
  storagePath: 'metadata_storage_path',
  storageLastModified: 'metadata_storage_last_modified',
  searchTermsResults: 'search_terms_results',
  searchTermsResultsOrder: 'search_terms_results_order',
  channelName: 'channelName',
  groupName: 'groupName',
  from: 'from',
  summary: 'summary',
};

/* Wildcard field is used when user doesn't fill any fiel
   and submits a query.

   default: field with name content or first field provided
*/
const wildcardFieldEnv = process.env.REACT_APP_QUERY_WILDCARD_FIELD;
export const wildcardField = wildcardFieldEnv && fieldsConfiguration.some((field) => field.name === wildcardFieldEnv)
  ? fieldsConfiguration.find((field) => field.name === wildcardFieldEnv)?.searchField
  : fieldsConfiguration.find((field) => field.name === 'content')?.searchField || fieldsConfiguration[0].searchField;
