import { SearchModeType } from 'shared/contexts/search-mode-context';

const useAvailableModes = (): SearchModeType[] => {
  const appModesEnv = process.env.REACT_APP_MODES;

  if (!appModesEnv) {
    return Object.values(SearchModeType) as SearchModeType[];
  }

  const appModes = appModesEnv.split(',');
  return appModes
    .map((modeValue) => modeValue as SearchModeType)
    .filter((modeValue) => Object.values(SearchModeType).includes(modeValue));
};

export default useAvailableModes;
