/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import actions from 'actions';
import {
  IAppState, IProjectCollection, IProjectCollectionDocument, IProjectItem, IProjectSavedQuery,
} from 'shared/interfaces';
import { apiLoginRequest } from 'authConfig';
import { useAccount, useMsal } from '@azure/msal-react';
import ProjectsService from 'services/projects';
import {
  FaAngleRight, FaCompress, FaExpand, FaFolder,
} from 'react-icons/fa';

import './index.css';
import useProjects from 'hooks/useProjects';
// import { * } from 'reducers/projectsReducer';
import ReactDOM from 'react-dom';
import Modal from '@material-ui/core/Modal';
import {
  addCollectionThunk, addFileThunk, addProjectThunk, addSavedQueryThunk,
} from 'reducers/projectsReducer/thunks';
import ProjectsList from './columns/projects-list';
import CollectionsList from './columns/collections-list';
import SelectorAction from './columns/selector-action';

export default function ProjectsSelector() {
  const dispatch = useDispatch();
  const navHistory = useHistory();

  const { instance, accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {});

  const projects = useSelector((state: IAppState) => state.projects);
  const [activateProjects, setActivateProjects] = useState(false);
  const shouldOpenProjectsSelector = useSelector((state: IAppState) => state.projects.UIopenProjectsSelector);
  const projectsSelectorType = useSelector((state: IAppState) => state.projects.UIprojectsSelectorType);
  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedDocument = useSelector((state: IAppState) => state.projects.UIprojectsSelectorDocument);
  const selectedCollection = useSelector((state: IAppState) => state.projects.UIselectedCollection);
  const searchBody = useSelector((state: IAppState) => state.searchBody);

  const selectorNavRef = useRef<null | HTMLDivElement>(null);
  const selectorProjectsListRef = useRef<null | HTMLDivElement>(null);
  const selectorCollectionsListRef = useRef<null | HTMLDivElement>(null);
  const selectorActionRef = useRef<null | HTMLDivElement>(null);

  const summary = selectedDocument?.summary || [];
  const summarySortingProp = process.env.REACT_APP_SEARCH_SUMMARY_ORDER ? process.env.REACT_APP_SEARCH_SUMMARY_ORDER : 'index';
  const summarySortingDirection = process.env.REACT_APP_SEARCH_SUMMARY_DIRECTION ? process.env.REACT_APP_SEARCH_SUMMARY_DIRECTION : 'asc';
  const sortedSummarySentences = summarySortingProp === 'index' ? summary : [...summary].sort((a:any, b:any) => {
    if (a[summarySortingProp] > b[summarySortingProp]) {
      return summarySortingDirection === 'asc' ? 1 : -1;
    }
    if (a[summarySortingProp] < b[summarySortingProp]) {
      return summarySortingDirection === 'asc' ? -1 : 1;
    }
    return 0;
  });

  const scrollTo = (columnType : string) => {
    let targetNode;

    switch (columnType) {
      case 'project-list':
        if (selectorProjectsListRef.current) targetNode = selectorProjectsListRef;
        break;
      case 'collections-list':
        if (selectorCollectionsListRef.current) targetNode = selectorCollectionsListRef;
        break;
      case 'selector-action':
        if (selectorActionRef.current) targetNode = selectorActionRef;
        break;
      default:
    }

    if (targetNode && targetNode.current) {
      selectorNavRef.current?.scroll({
        left: targetNode.current.offsetLeft - 50, top: targetNode.current.offsetTop, behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (projects.active) {
      setActivateProjects(true);
    }
  }, [projects]);

  /** ACTIONS ON PROJECTS * */
  const addProject = (name: string) => {
    if (name) {
      const newProject : IProjectItem = {
        ProjectID: `${projects.Username}-temp-${Date.now()}`,
        ProjectName: name,
        NoOfCollection: 0,
        Collection: [],
        NoOfSavedQueries: 0,
        SavedQueries: [],
        Shared: false,
      };
      dispatch(addProjectThunk(newProject));
    }
  };

  const addCollection = (name: string) => {
    if (name) {
      const newCollection : IProjectCollection = {
        ProjectId: projects.UIselectedProject?.ProjectID || '',
        CollectionName: name,
        Description: '',
        FileDataForCollection: [],
      };

      dispatch(addCollectionThunk(newCollection));
    }
  };

  const addQuery = (name: string) => {
    if (name) {
      const newQuery : IProjectSavedQuery = {
        SavedQueryName: name,
        Comment: '',
        Query: { ...searchBody },
      };
      if (projects.UIselectedProject !== undefined) {
        dispatch(addSavedQueryThunk(newQuery, projects.UIselectedProject));
      }
    }
  };

  const addDocument = () => {
    if (selectedDocument && selectedProject && selectedCollection) {
      const newDocument : IProjectCollectionDocument = {
        id: selectedDocument.storagePath,
        name: selectedDocument.title,
        path: selectedDocument.path,
        storagePath: selectedDocument.storagePath,
        summary: sortedSummarySentences.length > 0 ? sortedSummarySentences[0].sentence : '',
        comments: [],
      };
      if (projects.UIselectedProject !== undefined && projects.UIselectedCollection !== undefined) {
        dispatch(addFileThunk(newDocument, projects.UIselectedProject, projects.UIselectedCollection));
      }
    }
  };

  /** UI NAVIGATION OVER PROJECTS * */
  const selectProject = (project: IProjectItem) => {
    dispatch(actions.projectsSelectProject(project));
    if (projectsSelectorType === 'document') {
      dispatch(actions.projectsViewCollections(true));
      scrollTo('collections-list');
    }
    if (projectsSelectorType === 'query') {
      dispatch(actions.projectsViewQueries(true));
      scrollTo('selector-action');
    }
  };

  const selectCollection = (project: IProjectCollection) => {
    dispatch(actions.projectsSelectCollection(project));
    scrollTo('selector-action');
  };

  const handleClose = () => {
    dispatch(actions.projectsToggleProjectsSelector(false));
  };

  const confirmAction = (name?:string) => {
    if (projectsSelectorType === 'query' && name !== undefined) addQuery(name);
    if (projectsSelectorType === 'document' && selectedProject && selectedCollection) addDocument();
    handleClose();
  };

  return (
    <>
      { activateProjects && (
        <Modal
          open={shouldOpenProjectsSelector === true}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="global-variables"
        >
          <div className="projects projectsSelector" ref={selectorNavRef}>
            <div className="projects-nav">
              <ProjectsList addProject={addProject} selectProject={selectProject} innerRef={selectorProjectsListRef} />
              {
                projectsSelectorType === 'document' && (
                <CollectionsList innerRef={selectorCollectionsListRef} addCollection={addCollection} selectCollection={selectCollection} />
                )
              }
              <SelectorAction selectorType={projectsSelectorType} confirmAction={confirmAction} innerRef={selectorActionRef} />
            </div>
          </div>
        </Modal>

      )}
    </>
  );
}
