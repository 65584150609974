import useAvailableModes from 'hooks/useAvailableModes';
import React, { createContext, useState } from 'react';
import { SearchModeType } from 'shared/contexts/search-mode-context';

interface TabContextProps {
    selectedTab: SearchModeType;
    setSelectedTab: (event: React.ChangeEvent<{}>, value: SearchModeType) => void;
}

interface TabsProviderProps {
    children: React.ReactNode;
  }

export const TabContext = createContext<TabContextProps | null>(null);

export const TabProvider = ({ children }: TabsProviderProps) => {
  const availableModes = useAvailableModes();

  const initialTab = availableModes.length > 0 ? availableModes[0] : SearchModeType.Query;

  const [selectedTab, _setSelectedTab] = useState<SearchModeType>(initialTab);

  const setSelectedTab = (_: any, newTab: SearchModeType) => {
    _setSelectedTab(newTab);
  };
  return (
    <TabContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </TabContext.Provider>
  );
};
