import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState, IProjectItem } from 'shared/interfaces';
import { FaAngleRight, FaListUl, FaSearch } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import { BiFolderMinus } from 'react-icons/bi';
import { useConfirm } from 'material-ui-confirm';
import { deleteProjectThunk } from 'reducers/projectsReducer/thunks';
import DoubleClickInput from 'shared/components/doubleclick-input';

interface props {
  setViewCollections: (viewCollections: boolean) => void;
  setViewQueries: (viewQueries: boolean) => void;
  editProject?: (project: IProjectItem) => void;
  innerRef: any;
}

const FormattedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

export default function ProjectOptions({
  setViewCollections, setViewQueries, editProject, innerRef,
} : props) {
  const dispatch = useDispatch();

  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const viewCollections = useSelector((state: IAppState) => state.projects.UIviewCollections);
  const viewQueries = useSelector((state: IAppState) => state.projects.UIviewQueries);

  const confirm = useConfirm();

  const handleScrollToSelf = (e: any) => {
    e.stopPropagation();
    innerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  };

  const handleViewCollections = (e: any) => {
    e.stopPropagation();
    setViewCollections(true);
  };

  const handleViewQueries = (e: any) => {
    e.stopPropagation();
    setViewQueries(true);
  };

  const handleDeleteProject = () => {
    if (selectedProject !== undefined) {
      confirm({
        title: 'Are you sure you want to delete this project?', description: 'This action is permanent.', confirmationText: 'Yes', cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteProjectThunk(selectedProject));
        })
        .catch((error) => { console.log(error); });
    }
  };

  const handleProjectCommentChange = (event: any, value: string, project: IProjectItem) => {
    const editProjectItem : IProjectItem = {
      ProjectID: project.ProjectID,
      ProjectName: project.ProjectName,
      Comment: value,
      Collection: project.Collection,
      SavedQueries: project.SavedQueries,
      NoOfCollection: project.NoOfCollection,
      NoOfSavedQueries: project.NoOfSavedQueries,
      Shared: project.Shared,
    };
    if (editProject) editProject(editProjectItem);
  };

  return (
    <>
      {selectedProject
      && (
        <div className="projects-actions column">
          <div className="list" ref={innerRef} onClick={(event) => handleScrollToSelf(event)}>
            <div className={`list-item ${viewCollections ? 'active' : ''}`} onClick={(event) => handleViewCollections(event)}>
              <FaListUl className="icon-left" />
              Collections
              <FaAngleRight className="angle-right" />
            </div>
            <div className={`list-item ${viewQueries ? 'active' : ''}`} onClick={(event) => handleViewQueries(event)}>
              <FaSearch className="icon-left" />
              Queries
              <FaAngleRight className="angle-right" />
            </div>
            <div className="column-content">
              {/* {
                  selectedProject.Description && (
                    <p><strong>Description</strong><br />{selectedProject.Description}</p>
                  )
                } */}
              <strong className="textSmall">Description</strong>
              <DoubleClickInput
                inputType="textarea"
                inputValue={selectedProject.Description}
                placeholderText="Double click to add description"
                onInputChange={(e, value) => handleProjectCommentChange(e, value, selectedProject)}
              />
            </div>
            <div className="column-actions">
              <div className="column-content" style={{ padding: '0 16px' }}>
                <FormattedTooltip arrow placement="top" title="Remove project">
                  <button type="button" onClick={handleDeleteProject} aria-label="remove project">
                    <BiFolderMinus />
                  </button>
                </FormattedTooltip>
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  );
}

ProjectOptions.defaultProps = {
  editProject: undefined,
};
