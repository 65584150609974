import {
  IToSearch,
  IToSearchFields,
  ISearchResult,
  IPdfPreview,
  ISearchBody,
  IFacetsConfiguration,
  IProjects,
  IProjectItem,
  IProjectCollection,
  IProjectSavedQuery,
  IProjectCollectionDocument,
  IResultItem,
  ISearchResultHighlightPreview,
  IProjectsCollectionDocumentComment,
  IFolder,
  IFolderConfiguration,
  ISelectedFolder,
  IAskSearchResult,
  IAskSearchBody,
} from 'shared/interfaces';

const setTextToSearch = (command: IToSearch) => ({
  type: 'SET_TEXT_TO_SEARCH',
  payload: command,
});

const setFieldsToSearch = (command: IToSearchFields) => ({
  type: 'SET_FIELDS_TO_SEARCH',
  payload: command,
});

const setSearchResult = (command: ISearchResult) => ({
  type: 'SET_SEARCH_RESULT',
  payload: command,
});

const setSearchResultHighlight = (command: ISearchResultHighlightPreview) => ({
  type: 'SET_SEARCH_RESULT_HIGHLIGHT',
  payload: command,
});

const setPdfPreview = (command: IPdfPreview) => ({
  type: 'SET_PDF_PREVIEW',
  payload: command,
});

const setSearchBodyQuery = (command: ISearchBody) => ({
  type: 'SET_SEARCH_BODY_QUERY',
  payload: command,
});

const setFacetsConfiguration = (command: IFacetsConfiguration) => ({
  type: 'SET_FACETS_CONFIGURATION',
  payload: command,
});

const setProjects = (command: IProjects) => ({
  type: 'PROJECTS_SET_PROJECTS',
  payload: command,
});

const projectsAddProject = (command: IProjectItem) => ({
  type: 'PROJECTS_ADD_PROJECT',
  payload: command,
});

const projectsEditProject = (command: IProjectItem) => ({
  type: 'PROJECTS_EDIT_PROJECT',
  payload: command,
});

const projectsDeleteProject = (command: IProjectItem) => ({
  type: 'PROJECTS_DELETE_PROJECT',
  payload: command,
});

const projectsAddCollection = (command: IProjectCollection, id: string) => ({
  type: 'PROJECTS_ADD_COLLECTION',
  payload: command,
});

const projectsEditCollection = (command: IProjectCollection, id: string) => ({
  type: 'PROJECTS_EDIT_COLLECTION',
  payload: command,
});

const projectsDeleteCollection = (command: IProjectCollection) => ({
  type: 'PROJECTS_DELETE_COLLECTION',
  payload: command,
});

const projectsAddQuery = (command: IProjectSavedQuery) => ({
  type: 'PROJECTS_ADD_QUERY',
  payload: command,
});

const projectsEditQuery = (command: IProjectSavedQuery) => ({
  type: 'PROJECTS_EDIT_QUERY',
  payload: command,
});

const projectsDeleteQuery = (command: IProjectSavedQuery) => ({
  type: 'PROJECTS_DELETE_QUERY',
  payload: command,
});

const projectsSelectProject = (command: IProjectItem) => ({
  type: 'PROJECTS_SELECT_PROJECT',
  payload: command,
});

const projectsViewCollections = (command: boolean) => ({
  type: 'PROJECTS_VIEW_COLLECTIONS',
  payload: command,
});

const projectsViewQueries = (command: boolean) => ({
  type: 'PROJECTS_VIEW_QUERIES',
  payload: command,
});

const projectsSaveProjects = (command: IProjectItem) => ({
  type: 'PROJECTS_SAVE_PROJECTS',
  payload: command,
});

const projectsSelectCollection = (command: IProjectCollection) => ({
  type: 'PROJECTS_SELECT_COLLECTION',
  payload: command,
});

const projectsSelectQuery = (command: IProjectSavedQuery) => ({
  type: 'PROJECTS_SELECT_QUERY',
  payload: command,
});

const projectsSelectDocument = (command: IProjectCollectionDocument) => ({
  type: 'PROJECTS_SELECT_DOCUMENT',
  payload: command,
});

const projectsToggleProjectsSelector = (command: boolean) => ({
  type: 'PROJECTS_TOGGLE_SELECTOR',
  payload: command,
});

const projectsSetSelectorType = (command: string) => ({
  type: 'PROJECTS_SET_SELECTOR_TYPE',
  payload: command,
});

const projectsSetSelectorDocument = (command: IResultItem) => ({
  type: 'PROJECTS_SET_SELECTOR_DOCUMENT',
  payload: command,
});

const projectsAddDocument = (command: IProjectCollectionDocument) => ({
  type: 'PROJECTS_ADD_DOCUMENT',
  payload: command,
});

const projectsRemoveDocuments = (command: IProjectCollectionDocument[]) => ({
  type: 'PROJECTS_REMOVE_DOCUMENTS',
  payload: command,
});

const projectsRemoveDocument = (command: IProjectCollectionDocument) => ({
  type: 'PROJECTS_REMOVE_DOCUMENT',
  payload: command,
});

const projectsAddDocumentComment = (command: IProjectsCollectionDocumentComment) => ({
  type: 'PROJECTS_ADD_DOCUMENT_COMMENT',
  payload: command,
});

const projectsEditDocumentComment = (command: IProjectsCollectionDocumentComment) => ({
  type: 'PROJECTS_EDIT_DOCUMENT_COMMENT',
  payload: command,
});

const projectsRemoveDocumentComment = (command: IProjectsCollectionDocumentComment) => ({
  type: 'PROJECTS_REMOVE_DOCUMENT_COMMENT',
  payload: command,
});

const setProjectsActive = (command: boolean) => ({
  type: 'SET_PROJECTS_ACTIVE',
  payload: command,
});

const projectsDocumentDeleted = (command: string) => ({
  type: 'PROJECTS_DOCUMENT_DELETED',
  payload: command,
});

const setFolderResult = (command: IFolderConfiguration) => ({
  type: 'SET_FOLDER_RESULT',
  payload: command,
});

const setSelectedFolder = (command: ISelectedFolder) => ({
  type: 'SET_SELECTED_FOLDER',
  payload: command,
});

const setAskSearchResult = (command: IAskSearchResult) => ({
  type: 'SET_ASK_SEARCH_RESULT',
  payload: command,
});

const setTextToSearchAsk = (command: IToSearch) => ({
  type: 'SET_TEXT_TO_SEARCH_ASK',
  payload: command,
});

const setSearchAskBodyQuery = (command: IAskSearchBody) => ({
  type: 'SET_SEARCH_ASK_BODY_QUERY',
  payload: command,
});

export default {
  setTextToSearch,
  setSearchResult,
  setSearchResultHighlight,
  setProjects,
  setFieldsToSearch,
  setPdfPreview,
  setSearchBodyQuery,
  setFacetsConfiguration,
  setProjectsActive,
  projectsAddProject,
  projectsSelectProject,
  projectsSaveProjects,
  projectsViewCollections,
  projectsViewQueries,
  projectsSelectCollection,
  projectsAddCollection,
  projectsSelectQuery,
  projectsSelectDocument,
  projectsToggleProjectsSelector,
  projectsSetSelectorType,
  projectsAddQuery,
  projectsSetSelectorDocument,
  projectsAddDocument,
  projectsDeleteProject,
  projectsDeleteCollection,
  projectsDeleteQuery,
  projectsRemoveDocument,
  projectsRemoveDocuments,
  projectsEditProject,
  projectsEditCollection,
  projectsEditQuery,
  projectsDocumentDeleted,
  projectsAddDocumentComment,
  projectsRemoveDocumentComment,
  projectsEditDocumentComment,
  setFolderResult,
  setSelectedFolder,
  setChatSearchResult: setAskSearchResult,
  setTextToSearchChat: setTextToSearchAsk,
  setSearchChatBodyQuery: setSearchAskBodyQuery,
};
