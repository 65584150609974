import { servicesConfig } from 'authConfig';
import baseFetch from 'services/baseFetch';
import { UserData } from 'shared/interfaces';

const getUserData = async (): Promise<UserData | null> => {
  const searchServiceURL = `${servicesConfig.kmFuncAPIEndpoint}/api/user/allowance`;
  const defaultMessage = 'Error fetching user information.';
  try {
    const result = await baseFetch(searchServiceURL, {
      method: 'GET',
    });
    const userDataFromResponse: UserData = await result.data;
    return userDataFromResponse;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null; // Return null when there's an error
  }
};

export default getUserData;
