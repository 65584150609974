import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import actions from 'actions';
import { IAppState, IProjectCollection, IProjectItem } from 'shared/interfaces';
import { apiLoginRequest } from 'authConfig';
import { useAccount, useMsal } from '@azure/msal-react';
import { FaAngleRight, FaPlus } from 'react-icons/fa';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

interface props {
    selectorType?: 'document' | 'query';
    confirmAction: (name?:string) => void;
    innerRef: any;
}

export default function selectorAction({
  selectorType, confirmAction, innerRef,
} : props) {
  const dispatch = useDispatch();

  const { instance, accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {});

  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedCollection = useSelector((state: IAppState) => state.projects.UIselectedCollection);
  const selectedDocument = useSelector((state: IAppState) => state.projects.UIprojectsSelectorDocument);
  const viewCollections = useSelector((state: IAppState) => state.projects.UIviewCollections);
  const [addNewCollectionName, setAddNewCollectionName] = useState('');
  const [addNewQueryName, setAddNewQueryName] = useState('');

  useEffect(() => {
    innerRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  }, []);

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { value } = e.target;
    setAddNewQueryName(value);
  };

  const handleScrollToSelf = (e: any) => {
    e.stopPropagation();
    innerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
  };

  const isUniqueDocument = (storagePath: string) => {
    if (selectedCollection) {
      for (let i = 0; i < selectedCollection?.FileDataForCollection.length; i += 1) {
        if (selectedCollection?.FileDataForCollection[i].storagePath === storagePath) return false;
      }
      return true;
    }
    return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    confirmAction(addNewQueryName);
  };

  return (
    <>
      <div className="selector-action" ref={innerRef}>
        <form onSubmit={handleSubmit}>
          {
                selectorType === 'document' && (
                <>
                  {
                        selectedProject && selectedCollection && (
                        <p>Add <span style={{ wordBreak: 'break-word' }}>{selectedDocument?.title}</span> document to <br />
                          <strong>{selectedProject?.ProjectName}</strong>
                          &nbsp;&gt;&nbsp;<strong>{selectedCollection?.CollectionName}</strong>
                        </p>
                        )
                    }

                    {
                      selectedDocument && selectedCollection && !isUniqueDocument(selectedDocument?.storagePath) && (
                        <Typography variant="caption" align="center" color="error">
                          Document already in collection
                        </Typography>
                      )
                    }

                  {
                    selectedDocument && isUniqueDocument(selectedDocument?.storagePath) && (
                      <button type="submit" className="confirm-button" disabled={!(selectedProject !== undefined && selectedCollection !== undefined)}>
                        ADD
                      </button>
                    )
                  }
                </>
                )
            }
          { selectorType === 'query' && (
            <>
                {
                selectedProject && (
                <p>Save query in <strong>{selectedProject?.ProjectName}</strong></p>
                )
                }
              <input type="text" value={addNewQueryName} placeholder="query name" onChange={(e) => handleInputChange(e)} />
              <button type="submit" className="confirm-button" disabled={!(selectedProject?.ProjectName && addNewQueryName !== '')}>
                SAVE
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
}
