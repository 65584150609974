import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import actions from 'actions';
import {
  IAppState, IProjectCollection, IProjectCollectionDocument,
} from 'shared/interfaces';
import { apiLoginRequest, servicesConfig } from 'authConfig';
import { useAccount, useMsal } from '@azure/msal-react';
import {
  FaAngleRight, FaDownload, FaTrash,
} from 'react-icons/fa';
import { IoMdRemove } from 'react-icons/io';
import { BiFolderMinus } from 'react-icons/bi';
import './index.css';
import { spawn } from 'child_process';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import { Theme } from '@material-ui/core/styles';
import { deleteCollectionDocumentThunk, deleteCollectionThunk } from 'reducers/projectsReducer/thunks';
import { useConfirm } from 'material-ui-confirm';
import DoubleClickInput from 'shared/components/doubleclick-input';
import localforage from 'localforage';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CollectionDocument from './collection-document';

interface props {
  innerRef: any;
  selectDocument: (document : IProjectCollectionDocument) => void;
  viewDocument: () => void;
  editCollection?: (project: IProjectCollection) => void;
}

interface SnackbarMessage {
  message: string;
  key: number;
  severity: Color;
}
interface SnackbarState {
  open: boolean;
  snackPack: SnackbarMessage[];
  messageInfo?: SnackbarMessage;
}

const FormattedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

export default function collection({
  innerRef, selectDocument, viewDocument, editCollection,
}: props) {
  const dispatch = useDispatch();

  const { instance, accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {});
  const [isSuper, setIsSuper] = useState(false);

  const selectedProject = useSelector((state: IAppState) => state.projects.UIselectedProject);
  const selectedCollection = useSelector((state: IAppState) => state.projects.UIselectedCollection);
  const selectedDocument = useSelector((state: IAppState) => state.projects.UIselectedDocument);

  const [store, setStore] = useState<any | null>(null);

  const confirm = useConfirm();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  const [checkedState, setCheckedState] = useState(
    new Array(selectedCollection?.FileDataForCollection.length).fill(false),
  );
  const [checkedAllState, setCheckedAllState] = useState(false);

  const [totalSelected, setTotalSelected] = useState(0);

  const handleOnSelectAll = (selected: boolean) => {
    const updatedCheckedState = checkedState.map(() => (selected));
    setCheckedState(updatedCheckedState);
    setCheckedAllState(selected);

    const count = updatedCheckedState.filter(Boolean).length;
    selectDocument({} as IProjectCollectionDocument);
    setTotalSelected(count);
  };

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);

    const count = updatedCheckedState.filter(Boolean).length;

    if (count > 1) selectDocument({} as IProjectCollectionDocument);

    setTotalSelected(count);

    setCheckedAllState(count === checkedState.length && count > 0);
  };

  useEffect(() => {
    innerRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    setCheckedAllState(false);
    setTotalSelected(0);
    setCheckedState(new Array(selectedCollection?.FileDataForCollection.length).fill(false));
  }, [selectedCollection]);

  const handleDeleteCollection = () => {
    if (selectedProject !== undefined && selectedCollection !== undefined) {
      confirm({
        title: 'Are you sure you want to delete this collection?', description: 'This action is permanent.', confirmationText: 'Yes', cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteCollectionThunk(selectedCollection));
        })
        .catch((error) => { console.log(error); });
    }
  };

  const handleBulkRemoveDocumentFromCollection = () => {
    const selectedItems = selectedCollection?.FileDataForCollection.filter((d, ind) => checkedState[ind]) || [];
    if (selectedProject !== undefined && selectedCollection !== undefined && selectedItems.length > 0) {
      confirm({
        title:
          `Are you sure you want to remove ${selectedItems?.length > 1 ? 'these' : 'this'} 
          document${selectedItems?.length > 1 ? 's' : ''} 
          from ${selectedCollection.CollectionName}?`,
        description: 'This action is permanent.',
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
        .then(() => {
          dispatch(deleteCollectionDocumentThunk(selectedItems, selectedCollection));
        })
        .catch((error) => { console.log(error); });
    }
  };

  const setSnackPackFromChild = (properties: any) => {
    setSnackPack((prev) => [...prev,
      { message: properties.message, key: properties.key, severity: properties.severity }]);
  };

  const handleDownloadSelected = () => {
    const selectedItems = selectedCollection?.FileDataForCollection.filter((d, ind) => checkedState[ind]);
    if (currentAccount && selectedItems) {
      instance.acquireTokenSilent({
        ...apiLoginRequest,
        account: currentAccount,
      }).then(async (response) => {
        setSnackPack((prev) => [...prev,
          { message: `Downloading file${selectedItems?.length > 1 ? 's' : ''}`, key: new Date().getTime(), severity: 'info' }]);

        const payload = {
          uniqueKeyField: 'metadata_storage_path',
          uniqueValues: selectedItems.filter((file) => !file.isDeleted).map((file) => file.storagePath),
        };

        fetch(`${servicesConfig.kmFuncAPIEndpoint}/api/files/download/bulk`, {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
          .then((responseBlob) => responseBlob.blob().then((blob) => {
            let error = false;
            let errorMessage = '';
            if (responseBlob.status >= 400 && responseBlob.status < 600) {
              error = true;
              errorMessage = responseBlob.statusText;
            }

            return {
              error,
              errorMessage,
              raw: blob,
            };
          }))
          .then((data) => {
            if (!data.error) {
              const filename = selectedCollection?.CollectionName
                ? `${selectedCollection?.CollectionName} - File${selectedItems?.length > 1 ? 's' : ''}.zip`
                : 'Files.zip';
              const url = window.URL.createObjectURL(
                new Blob([data.raw]),
              );
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute(
                'download',
                filename,
              );

              document.body.appendChild(link);
              link.click();
              link?.parentNode?.removeChild(link);
            } else {
              setSnackPack((prev) => [...prev,
                { message: 'There was a problem downloading these document. Please try again.', key: new Date().getTime(), severity: 'warning' }]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const handleExitedSnackbar = () => {
    setMessageInfo(undefined);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCollectionNameChange = (event: any, value: string, editedCollection: IProjectCollection) => {
    const editCollectionItem : IProjectCollection = {
      ProjectId: editedCollection.ProjectId,
      CollectionId: editedCollection.CollectionId,
      CollectionName: value,
      Description: editedCollection.Description,
      FileDataForCollection: editedCollection.FileDataForCollection,
    };
    if (editCollection) editCollection(editCollectionItem);
  };

  const handleCollectionCommentChange = (event: any, value: string, editedCollection: IProjectCollection) => {
    const editCollectionItem : IProjectCollection = {
      ProjectId: editedCollection.ProjectId,
      CollectionId: editedCollection.CollectionId,
      CollectionName: editedCollection.CollectionName,
      Description: value,
      FileDataForCollection: editedCollection.FileDataForCollection,
    };
    if (editCollection) editCollection(editCollectionItem);
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenSnackbar(true);
    } else if (snackPack.length && messageInfo && openSnackbar) {
      setOpenSnackbar(false);
    }
  }, [snackPack, messageInfo, openSnackbar]);

  const onLoad = async () => {
    if (currentAccount) {
      instance.acquireTokenSilent({
        ...apiLoginRequest,
        account: currentAccount,
      }).then(async (response) => {
        if (currentAccount && response) {
          const claims : any = response.idTokenClaims;
          const deleteRole = process.env.REACT_APP_RBAC_DELETE_ROLE;
          const intersection = claims.roles.includes(deleteRole);

          if (intersection) {
            setIsSuper(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    const storeInstance = localforage.createInstance({ name: 'store' });
    onLoad();
    setStore(storeInstance);
  }, []);

  return (
    <>
      {
      selectedCollection && (
        <div className="collection column column-full" ref={innerRef}>
          {
      selectedCollection && (
      <div className="collection-container">
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          onExited={handleExitedSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={messageInfo?.severity || 'info'}>
            {messageInfo?.message}
          </Alert>
        </Snackbar>
        <div className="collection-header">
          <div className="collection-header-title">
            <strong>
              <DoubleClickInput
                inputType="text"
                inputValue={selectedCollection.CollectionName}
                onInputChange={(e, value) => handleCollectionNameChange(e, value, selectedCollection)}
              />
            </strong>
          </div>
          {
              totalSelected > 0 && (
              <div className="collection-header-actions">
                <span>bulk actions: </span>
                <ButtonGroup color="primary" aria-label="outlined button group">
                  <FormattedTooltip arrow placement="top" title={`Download selected document${totalSelected > 1 ? 's' : ''}`}>
                    <Button type="button" className="button" onClick={handleDownloadSelected}>
                      <FaDownload />
                    </Button>
                  </FormattedTooltip>
                  <FormattedTooltip arrow placement="top" title={`Remove selected document${totalSelected > 1 ? 's' : ''} from collection`}>
                    <Button type="button" className="button" onClick={handleBulkRemoveDocumentFromCollection}>
                      <IoMdRemove />
                    </Button>
                  </FormattedTooltip>
                  {/* TODO: Should we allow bulk delete? */}
                  {/* {isSuper && (
                  <FormattedTooltip arrow placement="top" title={`Delete selected document${totalSelected > 1 ? 's' : ''}`}>
                    <Button type="button" className="button delete-button">
                      <FaTrash />
                    </Button>
                  </FormattedTooltip>
                  )} */}
                </ButtonGroup>
              </div>
              )
            }

        </div>
        <div className="collection-items">
          <div className="column">
            <div className="column-content">
              <strong className="textSmall">Description</strong>
              <DoubleClickInput
                inputType="textarea"
                inputValue={selectedCollection.Description}
                placeholderText="Double click to add description"
                onInputChange={(e, value) => handleCollectionCommentChange(e, value, selectedCollection)}
              />
            </div>
            <div className="column-actions">
              <div className="column-content" style={{ padding: '0 16px' }}>
                <FormattedTooltip arrow placement="top" title="Remove collection" onClick={handleDeleteCollection}>
                  <Button type="button">
                    <BiFolderMinus />
                  </Button>
                </FormattedTooltip>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="collection-header-selector">
              <input
                type="checkbox"
                id="checkAllCheckbox"
                name="checkAll"
                value="checkAll"
                checked={checkedAllState}
                onChange={(e) => handleOnSelectAll(e.target.checked)}
                style={{ marginRight: '8px', marginLeft: '0' }}
              />
              <label htmlFor="checkAllCheckbox"><span>Select all</span>
                {
              totalSelected > 0 && (<span> <i>({totalSelected} selected)</i></span>)
            }
              </label>
            </div>
            <div className="list">
              {selectedCollection
        && selectedCollection.FileDataForCollection.map((item, index) => (
          <div
            key={item.id}
            className={`list-item ${item.id === selectedDocument?.id ? 'active' : ''} ${item.isDeleted ? 'deleted' : ''}`}
            onClick={() => selectDocument(item)}
          >
            <input
              type="checkbox"
              id={`custom-checkbox-${index}`}
              name={item.id}
              value={item.id}
              checked={checkedState[index] || false}
              onChange={() => handleOnChange(index)}
              style={{ marginRight: '8px', marginLeft: '0' }}
            />
            <span className="list-item-text">

              {item.isDeleted && (
              <><FaTrash size="0.8em" color="grey" /><span>&nbsp;</span></>
              )}
              {item.name}
            </span>
            <FaAngleRight className="angle-right" />
          </div>
        ))}
            </div>
          </div>
          {
            selectedDocument && selectedDocument.id && (
              <CollectionDocument key={`${selectedCollection.CollectionId}`} viewDocument={viewDocument} setParentSnackPack={setSnackPackFromChild} />
            )
          }

        </div>
      </div>
      )
    }
        </div>
      )
    }

    </>
  );
}

collection.defaultProps = {
  editCollection: undefined,
};
