import actions from 'actions';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { CreateUserData, DeleteUserData, UpdateUserData } from 'services/projects';
import UserDataOperations from 'shared/enums/UserdataOperations';
import UserDataScopes from 'shared/enums/UserdataScopes';
import {
  IProjectCollection, IProjectCollectionDocument, IProjectItem, IProjectSavedQuery, IProjectsCollectionDocumentComment, IURLParam,
} from 'shared/interfaces';
import { RootState } from 'store';

const handleResponse = (response: any) => {
  if (response.error) {
    throw Error(response.errorMessage);
  }
  return response.data;
};

const dispatchAction = (dispatch: any, action: any, payload: any) => {
  dispatch(action(payload));
};

const handleException = (error: any, dispatch: any) => {
  // TODO: dispatch error action
  // dispatch(actions.onActionError(`An error occurred: ${error.message}`));
};

export const addProjectThunk = (
  newProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const newProjectPayload = {
    Name: newProject.ProjectName,
  };

  try {
    const response = await CreateUserData(JSON.stringify(newProjectPayload), UserDataOperations.Create, UserDataScopes.Project);
    const data = handleResponse(response);
    const updatedProject = { ...newProject, ProjectID: data.id };
    dispatchAction(dispatch, actions.projectsAddProject, updatedProject);
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const addCollectionThunk = (
  newCollection: IProjectCollection,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch, getState) => {
  const { projects } = getState();

  const newCollectionPayload = {
    ProjectId: newCollection.ProjectId,
    Description: newCollection.Description,
    Name: newCollection.CollectionName,
  };

  try {
    const response = await CreateUserData(JSON.stringify(newCollectionPayload), UserDataOperations.Create, UserDataScopes.Collection);
    const data = handleResponse(response);

    const updatedCollection = { ...newCollection, CollectionId: data.id };

    dispatch(actions.projectsAddCollection(updatedCollection, projects.UIselectedProject.ProjectID));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const addSavedQueryThunk = (
  newSavedQuery: IProjectSavedQuery,
  parentProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const newSavedQueryPayload = {
    ProjectId: parentProject.ProjectID,
    Name: newSavedQuery.SavedQueryName,
    Query: JSON.stringify(newSavedQuery.Query),
  };

  try {
    const response = await CreateUserData(JSON.stringify(newSavedQueryPayload), UserDataOperations.Create, UserDataScopes.SavedQuery);
    const data = handleResponse(response);

    const updatedSavedQuery = { ...newSavedQuery, SavedQueryId: data.id };

    dispatch(actions.projectsAddQuery(updatedSavedQuery));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const addFileThunk = (
  newFile: IProjectCollectionDocument,
  parentProject: IProjectItem,
  parentCollection: IProjectCollection,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const newFilePayload = {
    CollectionId: parentCollection.CollectionId,
    MetadataStoragePath: newFile.storagePath,
    Name: newFile.name,
    Description: newFile.summary,
  };

  try {
    const response = await CreateUserData(JSON.stringify(newFilePayload), UserDataOperations.Create, UserDataScopes.File);
    const data = handleResponse(response);

    const newlyCreatedFile = data;
    newlyCreatedFile.storagePath = data.metadataStoragePath;
    newlyCreatedFile.path = btoa(data.metadataStoragePath);
    newlyCreatedFile.comments = [];
    console.log(newlyCreatedFile);
    dispatch(actions.projectsAddDocument(newlyCreatedFile));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const addCollectionDocumentCommentThunk = (
  commentText: string,
  parentProject: IProjectItem,
  parentCollection: IProjectCollection,
  parentDocument: IProjectCollectionDocument,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const newFileCommentPayload = {
    CollectionId: parentCollection.CollectionId,
    FileId: parentDocument.id,
    Text: commentText,
  };

  try {
    const response = await CreateUserData(JSON.stringify(newFileCommentPayload), UserDataOperations.Create, UserDataScopes.Comment);
    const data = handleResponse(response);

    const newlyCreatedFileComment : IProjectsCollectionDocumentComment = {
      CommentId: data.id,
      CommentByUserName: data.createdBy,
      CommentByDisplayName: data.createdByName,
      CommentCreateDateTime: new Date(data.createdOn),
      CommentModifiedDateTime: new Date(data.modifiedOn),
      CommentText: data.text,
      CommentUserId: data.UserId,
    };
    dispatch(actions.projectsAddDocumentComment(newlyCreatedFileComment));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const editProjectThunk = (
  editProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const editProjectPayload = {
    ProjectId: editProject.ProjectID,
    Name: editProject.ProjectName,
    Description: editProject.Comment,
  };

  try {
    const response = await UpdateUserData(JSON.stringify(editProjectPayload), UserDataOperations.Update, UserDataScopes.Project);
    const data = handleResponse(response);

    dispatch(actions.projectsEditProject(data));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const editCollectionThunk = (
  editCollection: IProjectCollection,
  parentProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch, getState) => {
  const { projects } = getState();

  const editCollectionPayload = {
    CollectionId: editCollection.CollectionId,
    ProjectId: parentProject.ProjectID,
    Description: editCollection.Description,
    Name: editCollection.CollectionName,
  };

  try {
    const response = await UpdateUserData(JSON.stringify(editCollectionPayload), UserDataOperations.Update, UserDataScopes.Collection);
    const data = handleResponse(response);
    dispatch(actions.projectsEditCollection(data, projects.UIselectedProject.ProjectID));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const editSavedQueryThunk = (
  editSavedQuery: IProjectSavedQuery,
  parentProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const editSavedQueryPayload = {
    ProjectId: parentProject.ProjectID,
    SavedQueryId: editSavedQuery.SavedQueryId,
    Description: editSavedQuery.Comment,
    Name: editSavedQuery.SavedQueryName,
    Query: JSON.stringify(editSavedQuery.Query),
  };

  try {
    const response = await UpdateUserData(JSON.stringify(editSavedQueryPayload), UserDataOperations.Update, UserDataScopes.SavedQuery);
    const data = handleResponse(response);
    dispatch(actions.projectsEditQuery(data));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const deleteProjectThunk = (
  deleteProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  try {
    const params : IURLParam [] = [
      {
        key: 'projectId',
        value: deleteProject.ProjectID,
      },
    ];
    const response = await DeleteUserData(undefined, UserDataOperations.Delete, UserDataScopes.Project, params);
    handleResponse(response);
    dispatch(actions.projectsDeleteProject(deleteProject));
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const deleteCollectionThunk = (
  deleteCollection: IProjectCollection,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const deleteCollectionPayload = {
  };

  try {
    if (deleteCollection.CollectionId) {
      const params : IURLParam [] = [
        {
          key: 'collectionId',
          value: deleteCollection.CollectionId,
        },
      ];
      const response = await DeleteUserData(JSON.stringify(deleteCollectionPayload), UserDataOperations.Delete, UserDataScopes.Collection, params);
      handleResponse(response);
      dispatch(actions.projectsDeleteCollection(deleteCollection));
    }
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const deleteQueryThunk = (
  deleteSavedQuery: IProjectSavedQuery,
  parentProject: IProjectItem,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  try {
    if (deleteSavedQuery.SavedQueryId) {
      const params : IURLParam [] = [
        {
          key: 'savedQueryId',
          value: deleteSavedQuery.SavedQueryId,
        },
        {
          key: 'projectId',
          value: parentProject.ProjectID,
        },
      ];
      const response = await DeleteUserData(undefined, UserDataOperations.Delete, UserDataScopes.SavedQuery, params);
      handleResponse(response);
      dispatch(actions.projectsDeleteQuery(deleteSavedQuery));
    }
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const deleteCollectionDocumentThunk = (
  deleteFile: IProjectCollectionDocument | IProjectCollectionDocument[],
  parentCollection: IProjectCollection,
) : ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  let filesToBeDeleted = [];
  if (Array.isArray(deleteFile)) {
    filesToBeDeleted = deleteFile.map((item) => item.id);
  } else {
    filesToBeDeleted.push(deleteFile.id);
  }

  const deleteCollectionDocumentPayload = {
    uniqueKeyField: 'Id',
    uniqueValues: filesToBeDeleted,
  };

  try {
    if (parentCollection.CollectionId) {
      const params : IURLParam [] = [
        {
          key: 'collectionId',
          value: parentCollection.CollectionId,
        },
      ];
      const response = await DeleteUserData(
        JSON.stringify(deleteCollectionDocumentPayload),
        UserDataOperations.Delete,
        UserDataScopes.Files, params,
      );
      handleResponse(response);
      if (Array.isArray(deleteFile)) {
        dispatch(actions.projectsRemoveDocuments(deleteFile));
      } else {
        dispatch(actions.projectsRemoveDocument(deleteFile));
      }
    }
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const deleteCollectionDocumentCommentThunk = (
  comment: IProjectsCollectionDocumentComment,
  selectedFile: IProjectCollectionDocument,
  parentCollection: IProjectCollection,
): ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const deleteCollectionDocumentPayload = {};

  try {
    if (parentCollection.CollectionId) {
      const params : IURLParam [] = [
        {
          key: 'commentId',
          value: comment.CommentId,
        },
        {
          key: 'collectionId',
          value: parentCollection.CollectionId,
        },
        {
          key: 'fileId',
          value: selectedFile.id,
        },
      ];

      const response = await DeleteUserData(JSON.stringify(deleteCollectionDocumentPayload), UserDataOperations.Delete, UserDataScopes.Comment, params);
      if (!response.error) { dispatch(actions.projectsRemoveDocumentComment(comment)); }
    }
  } catch (error) {
    handleException(error, dispatch);
  }
};

export const editCollectionDocumentCommentThunk = (
  comment: IProjectsCollectionDocumentComment,
  selectedFile: IProjectCollectionDocument,
  parentCollection: IProjectCollection,
  parentProject: IProjectItem,
): ThunkAction<void, RootState, never, AnyAction> => async (dispatch) => {
  const editCollectionPayload = {
    ProjectId: parentProject.ProjectID,
    CollectionId: parentCollection.CollectionId,
    MetadataStoragePath: selectedFile.storagePath,
    CommentId: comment.CommentId,
    Comment: comment.CommentText,
  };

  try {
    const response = await UpdateUserData(JSON.stringify(editCollectionPayload), UserDataOperations.Update, UserDataScopes.Comment);
    const data = handleResponse(response);
    if (Array.isArray(data)) {
      const editedComment = data.find((c) => c.CommentId === editCollectionPayload.CommentId);
      if (editedComment) {
        dispatch(actions.projectsEditDocumentComment(editedComment));
      }
    }
  } catch (error) {
    handleException(error, dispatch);
  }
};
